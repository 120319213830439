/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/styles/index.scss"

export const onRenderBody = ({ setBodyAttributes }) => {
  setBodyAttributes({
    className: "active-dark",
  })
}
